exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---themes-gatsby-theme-photography-portfolio-src-pages-404-js": () => import("./../../../themes/gatsby-theme-photography-portfolio/src/pages/404.js" /* webpackChunkName: "component---themes-gatsby-theme-photography-portfolio-src-pages-404-js" */),
  "component---themes-gatsby-theme-photography-portfolio-src-pages-index-js": () => import("./../../../themes/gatsby-theme-photography-portfolio/src/pages/index.js" /* webpackChunkName: "component---themes-gatsby-theme-photography-portfolio-src-pages-index-js" */),
  "component---themes-gatsby-theme-photography-portfolio-src-templates-category-page-index-js": () => import("./../../../themes/gatsby-theme-photography-portfolio/src/templates/CategoryPage/index.js" /* webpackChunkName: "component---themes-gatsby-theme-photography-portfolio-src-templates-category-page-index-js" */),
  "component---themes-gatsby-theme-photography-portfolio-src-templates-photo-page-index-js": () => import("./../../../themes/gatsby-theme-photography-portfolio/src/templates/PhotoPage/index.js" /* webpackChunkName: "component---themes-gatsby-theme-photography-portfolio-src-templates-photo-page-index-js" */)
}

